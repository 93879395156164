import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

gsap.registerPlugin(ScrollTrigger);

const AnimationWrapper = styled.div`
  overflow: hidden;
  opacity: 0;
  transform: translateY(100px);
  clip-path: inset(100% 0 0 0);
`;

const TitleAnimation = ({ children }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const element = titleRef.current;
    
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=100',
        toggleActions: 'play none none reverse'
      }
    });

    tl.set(element, {
      opacity: 0,
      y: 100,
      clipPath: 'inset(100% 0 0 0)',
    })
    .to(element, {
      opacity: 1,
      clipPath: 'inset(0% 0 0 0)',
      y: 0,
      duration: 1.2,
      ease: 'power4.inOut',
    });

    return () => {
      if (tl) tl.kill();
    };
  }, []);

  return (
    <AnimationWrapper ref={titleRef}>
      {children}
    </AnimationWrapper>
  );
};

export default TitleAnimation;
