import React, { useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh;
  overflow: hidden;
`;

const TextGroup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: min-content;
`;

const TextLine = styled.div`
  font-size: clamp(3rem, 10vw, 8rem);
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  opacity: ${props => props.$isMemories ? 1 : 1};
  transform: ${props => props.$isMemories ? 'translateX(100%)' : 'translateY(100px)'};
  clip-path: ${props => !props.$isMemories ? 'inset(100% 0 0 0)' : 'none'};
  color: #fff;
  width: 100%;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 5vw;

  @media (max-width: 768px) {
    font-size: clamp(2rem, 8vw, 4rem);
  }
`;

const WhiteOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  transform-origin: bottom;
  z-index: 2;
  mix-blend-mode: difference;
`;

const Loader = ({ onComplete }) => {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.set('.memories-text', {
      x: '100%',
    })
    .to('.design-text', {
      clipPath: 'inset(0% 0 0 0)',
      y: 0,
      duration: 1.0,
      stagger: 0.12,
      ease: 'power4.inOut',
    })
    .to('.design-text', {
      x: '-150%',
      duration: 1.4,
      stagger: 0.15,
      ease: 'power2.inOut',
    }, '+=0.3')
    .to('.memories-text', {
      x: '0%',
      duration: 1.2,
      stagger: 0.15,
      ease: 'power2.inOut',
    }, '<0')
    .to('.white-overlay', {
      height: '100%',
      duration: 0.8,
      ease: 'power3.inOut',
    })
    .to('.loader-container', {
      clipPath: 'inset(0 0 100% 0)',
      duration: 1.0,
      ease: 'power3.inOut',
    })
    .to('.loader-container', {
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete
    });

  }, [onComplete]);

  return (
    <LoaderContainer className="loader-container">
      <TextContainer>
        <TextGroup className="design-group">
          {[...Array(4)].map((_, i) => (
            <TextLine key={`design-${i}`} className="design-text">
              we do not design
            </TextLine>
          ))}
        </TextGroup>
        <TextGroup className="memories-group" $isMemories>
          {[...Array(4)].map((_, i) => (
            <TextLine 
              key={`memories-${i}`} 
              className="memories-text"
              $isMemories
            >
              We make memories
            </TextLine>
          ))}
        </TextGroup>
      </TextContainer>
      <WhiteOverlay className="white-overlay" />
    </LoaderContainer>
  );
};

export default Loader;
