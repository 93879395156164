import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TeamSection = styled.section`
  padding: 8rem 0;
  background: #0C0C0C;
  color: white;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const TeamContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const TeamTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 4rem;
  text-align: center;
  font-family: 'Universal Sans Display', sans-serif;
  letter-spacing: -0.02em;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
`;

const TeamMemberCard = styled(motion.div)`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    filter: grayscale(100%);
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    img {
      filter: grayscale(0%);
      transform: scale(1.05);
    }

    .content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  h3 {
    color: white;
    margin: 0;
    font-size: 1.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin: 0.5rem 0 0;
  }
`;

const teamMembers = [
  {
    name: 'Sarah Johnson',
    role: 'Creative Director',
    image: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'Michael Chen',
    role: 'Lead Developer',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'Emma Rodriguez',
    role: 'Art Director',
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'David Kim',
    role: 'Technical Lead',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'Lisa Thompson',
    role: 'UI/UX Designer',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'James Wilson',
    role: 'Backend Developer',
    image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'Sofia Garcia',
    role: 'Product Manager',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=500&auto=format&fit=crop'
  },
  {
    name: 'Alex Foster',
    role: 'Motion Designer',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=500&auto=format&fit=crop'
  }
];

const Team = () => {
  return (
    <TeamSection>
      <TeamContent>
        <TeamTitle>Our Team</TeamTitle>
        <TeamGrid>
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <img src={member.image} alt={member.name} />
              <div className="content">
                <h3>{member.name}</h3>
                <p>{member.role}</p>
              </div>
            </TeamMemberCard>
          ))}
        </TeamGrid>
      </TeamContent>
    </TeamSection>
  );
};

export default Team;
