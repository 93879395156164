import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { motion, useSpring, useMotionValue } from 'framer-motion';

const CursorDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  border-radius: 50%;
  opacity: ${props => props.isVideoHover || props.isProjectHover ? 0 : 1};
  will-change: transform;
  transform: translate3d(0, 0, 0);
`;

const CursorCircle = styled(motion.div)`
  width: ${props => props.isVideoHover || props.isProjectHover ? '180px' : '32px'};
  height: ${props => props.isVideoHover || props.isProjectHover ? '48px' : '32px'};
  border: ${props => props.isVideoHover || props.isProjectHover ? 'none' : '1px solid white'};
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  border-radius: ${props => props.isVideoHover || props.isProjectHover ? '8px' : '50%'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isVideoHover || props.isProjectHover) ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};
  backdrop-filter: ${props => (props.isVideoHover || props.isProjectHover) ? 'blur(8px)' : 'none'};
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1), 
              height 0.2s cubic-bezier(0.4, 0, 0.2, 1), 
              border-radius 0.2s cubic-bezier(0.4, 0, 0.2, 1),
              border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
              background 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, width, height;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0.2));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: ${props => props.isVideoHover || props.isProjectHover ? 0 : 0};
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  span {
    opacity: ${props => (props.isVideoHover || props.isProjectHover) ? 1 : 0};
    transform: translateY(${props => (props.isVideoHover || props.isProjectHover) ? '0' : '10px'});
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    will-change: transform, opacity;

    &::before {
      content: ${props => props.isProjectHover ? "'View Case Study'" : "'Click to Watch'"};
    }
  }
`;

const CustomCursor = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [isProjectHover, setIsProjectHover] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(window.innerWidth <= 1024);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  // Inner dot spring config - very quick follow
  const dotSpringConfig = { stiffness: 300, damping: 20, mass: 0.5 };
  
  // Outer circle spring config - slight delay
  const circleSpringConfig = { stiffness: 200, damping: 18, mass: 0.8 };
  
  const smoothX = useSpring(mouseX, dotSpringConfig);
  const smoothY = useSpring(mouseY, dotSpringConfig);
  const outerSmoothX = useSpring(mouseX, circleSpringConfig);
  const outerSmoothY = useSpring(mouseY, circleSpringConfig);

  useEffect(() => {
    const handleReset = () => {
      setIsHovering(false);
      setIsVideoHover(false);
      setIsProjectHover(false);
    };

    window.addEventListener('resetCursor', handleReset);
    return () => {
      window.removeEventListener('resetCursor', handleReset);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Use requestAnimationFrame for smooth performance
      window.requestAnimationFrame(() => {
        mouseX.set(e.clientX);
        mouseY.set(e.clientY);
      });
    };

    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseX, mouseY]);

  useEffect(() => {
    const mouseOver = (e) => {
      const target = e.target.closest('.project-card-hover') || e.target;
      const isProjectPage = window.location.pathname.includes('/project/');
      
      if ((target.tagName === 'A' || target.tagName === 'BUTTON') && !isProjectPage) {
        setIsHovering(true);
      }
      if (target.classList.contains('video-click-area') && !isProjectPage) {
        setIsVideoHover(true);
      }
      if (target.classList.contains('project-card-hover') && !isProjectPage) {
        setIsProjectHover(true);
        setIsVideoHover(false);
      }
    };

    const mouseOut = (e) => {
      const target = e.target.closest('.project-card-hover') || e.target;
      
      setIsHovering(false);
      setIsVideoHover(false);
      setIsProjectHover(false);
    };

    window.addEventListener('mouseover', mouseOver, { passive: true });
    window.addEventListener('mouseout', mouseOut, { passive: true });

    return () => {
      window.removeEventListener('mouseover', mouseOver);
      window.removeEventListener('mouseout', mouseOut);
    };
  }, []);

  const circleStyle = useMemo(() => ({
    transform: `translate3d(${mouseX}px, ${mouseY + (isVideoHover || isProjectHover ? -25 : 0)}px, 0)`,
    x: isVideoHover || isProjectHover ? -90 : -16,
    y: isVideoHover || isProjectHover ? -24 : -16,
  }), [mouseX, mouseY, isVideoHover, isProjectHover]);

  const dotStyle = useMemo(() => ({
    translateX: smoothX,
    translateY: smoothY,
    x: -4,
    y: -4
  }), [smoothX, smoothY]);

  const circleTransformStyle = useMemo(() => ({
    translateX: outerSmoothX,
    translateY: outerSmoothY,
    x: circleStyle.x,
    y: circleStyle.y - (isVideoHover || isProjectHover ? 25 : 0)
  }), [outerSmoothX, outerSmoothY, circleStyle.x, circleStyle.y, isVideoHover, isProjectHover]);

  if (isMobileOrTablet) return null;

  return (
    <>
      <CursorDot
        style={dotStyle}
        isVideoHover={isVideoHover}
        isProjectHover={isProjectHover}
      />
      <CursorCircle
        style={circleTransformStyle}
        isVideoHover={isVideoHover}
        isProjectHover={isProjectHover}
      >
        <span />
      </CursorCircle>
    </>
  );
};

export default CustomCursor;
