import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register the ScrollTrigger plugin
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const NavContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1.5rem 5vw;
  width: 100%;
  transform: translateY(${props => props.$hideNav ? '-100%' : '0'});
  transition: transform 0.3s ease, background-color 0.3s ease;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    opacity: ${props => props.$isScrolled ? 1 : 0};
    transition: opacity 0.3s ease;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    background: rgba(15, 15, 15, 0.8);
    opacity: ${props => props.$isScrolled ? 1 : 0};
    transition: opacity 0.3s ease;
    z-index: -1;
  }
`;

const NavContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  z-index: 2;
  padding: 0.5rem;
  position: relative;
  width: 60px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const DesktopMenu = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    gap: 3rem;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const DesktopLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: 'Universal Sans Display', sans-serif;
  font-weight: 400;
  position: relative;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  width: max-content;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  &.active {
    color: #888;
  }
`;

const HireUsButton = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Universal Sans Display', sans-serif;
  font-weight: 400;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.75rem 2rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  width: max-content;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  &:hover {
    background: white;
    color: #0f0f0f;
    border-color: white;
  }

  &.active {
    background: white;
    color: #0f0f0f;
    border-color: white;
  }
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: difference;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

const HamburgerIcon = styled.div`
  width: 24px;
  height: 2px;
  background-color: white;
  position: relative;
  transition: all 0.3s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease;
  }

  &::before {
    transform: translateY(-8px);
  }

  &::after {
    transform: translateY(8px);
  }

  ${({ isOpen }) => isOpen && `
    background-color: transparent;
    
    &::before {
      transform: rotate(45deg);
    }
    
    &::after {
      transform: rotate(-45deg);
    }
  `}
`;

const MenuOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  z-index: 1;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;

const MenuContent = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;

  a {
    font-size: 4rem;
    padding: 1rem 0;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.5s ease;
    transform: translateY(20px);
    opacity: 0;

    &.open {
      transform: translateY(0);
      opacity: 1;
    }

    &:hover {
      color: #888;
    }
  }
`;

const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const MenuLink = styled(motion(Link))`
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: 'Universal Sans Display', sans-serif;
  font-weight: 400;
  padding: 1rem 0;
  transition: color 0.3s ease;
  position: relative;
  width: max-content;

  &:hover {
    color: #888;
  }

  &.active {
    color: #888;
  }
`;

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      
      // Only start hiding after 400px
      if (currentScrollPos > 400) {
        const visible = prevScrollPos > currentScrollPos;
        setHideNav(!visible);
      } else {
        setHideNav(false);
      }

      setPrevScrollPos(currentScrollPos);
      setIsScrolled(currentScrollPos > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    if (isMenuOpen) {
      setHideNav(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.body.style.opacity = '1'; // Reset opacity when component mounts
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavClick = () => {
    document.body.style.opacity = '0'; // Immediately hide content
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuVariants = {
    closed: {
      opacity: 0,
      transition: {
        duration: 0.5
      }
    },
    open: {
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const linkVariants = {
    closed: {
      opacity: 0,
      y: 50
    },
    open: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <>
      <NavContainer 
        $hideNav={hideNav} 
        $isScrolled={isScrolled}
      >
        <NavContent>
          <LogoContainer to="/">
            <Logo />
          </LogoContainer>
          <DesktopMenu>
            <DesktopLink to="/" className={isActive('/') ? 'active' : ''} onClick={handleNavClick}>
              Work
            </DesktopLink>
            <DesktopLink to="/about" className={isActive('/about') ? 'active' : ''} onClick={handleNavClick}>
              About
            </DesktopLink>
            <DesktopLink to="/contact" className={isActive('/contact') ? 'active' : ''} onClick={handleNavClick}>
              Contact
            </DesktopLink>
            <HireUsButton to="/hire-us" className={isActive('/hire-us') ? 'active' : ''} onClick={handleNavClick}>
              Hire Us
            </HireUsButton>
          </DesktopMenu>
          <MenuButton onClick={toggleMenu}>
            <HamburgerIcon isOpen={isMenuOpen} />
          </MenuButton>
        </NavContent>
        <AnimatePresence>
          {isMenuOpen && (
            <MenuOverlay
              initial={{ opacity: 0, visibility: 'hidden' }}
              animate={{ opacity: 1, visibility: 'visible' }}
              exit={{ opacity: 0, visibility: 'hidden' }}
              transition={{ duration: 0.3 }}
              className={isMenuOpen ? 'open' : ''}
            >
              <MenuContent
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <MenuLinks>
                  <MenuLink
                    to="/"
                    className={isActive('/') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    Work
                  </MenuLink>
                  <MenuLink
                    to="/about"
                    className={isActive('/about') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    About
                  </MenuLink>
                  <MenuLink
                    to="/contact"
                    className={isActive('/contact') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                  >
                    Contact
                  </MenuLink>
                  <MenuLink
                    to="/hire-us"
                    className={isActive('/hire-us') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    Hire Us
                  </MenuLink>
                </MenuLinks>
              </MenuContent>
            </MenuOverlay>
          )}
        </AnimatePresence>
      </NavContainer>
      {/*
        <AnimatePresence>
          {isMenuOpen && (
            <MenuOverlay
              initial={{ opacity: 0, visibility: 'hidden' }}
              animate={{ opacity: 1, visibility: 'visible' }}
              exit={{ opacity: 0, visibility: 'hidden' }}
              transition={{ duration: 0.3 }}
              className={isMenuOpen ? 'open' : ''}
            >
              <MenuContent
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <MenuLinks>
                  <MenuLink
                    to="/"
                    className={isActive('/') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    Work
                  </MenuLink>
                  <MenuLink
                    to="/about"
                    className={isActive('/about') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    About
                  </MenuLink>
                  <MenuLink
                    to="/contact"
                    className={isActive('/contact') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                  >
                    Contact
                  </MenuLink>
                  <MenuLink
                    to="/hire-us"
                    className={isActive('/hire-us') ? 'active' : ''}
                    onClick={handleNavClick}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    Hire Us
                  </MenuLink>
                </MenuLinks>
              </MenuContent>
            </MenuOverlay>
          )}
        </AnimatePresence>
      */}
    </>
  );
};

export default Navigation;
