import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageTitle from './PageTitle';

const AboutContainer = styled.div`
  min-height: 100vh;
  padding: 120px 5vw 5vw 5vw;
  background: #0C0C0C;
  color: white;
  font-family: 'Universal Sans Display', sans-serif;
`;

const AboutContent = styled.div`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
`;

const Description = styled(motion.p)`
  font-size: 24px;
  max-width: 800px;
  margin-bottom: 4rem;
  opacity: 0.8;
  line-height: 1.6;
  font-family: inherit;
`;

const Section = styled(motion.div)`
  margin: 6rem 0;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 64px;
  margin-bottom: 2rem;
  font-weight: 600;
  font-family: var(--font-universal);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 4rem;
  margin-top: 4rem;
`;

const GridItem = styled(motion.div)`
  h3 {
    font-size: 32px;
    margin-bottom: 1rem;
    font-weight: 600;
    font-family: var(--font-universal);
  }

  p {
    font-size: 18px;
    opacity: 0.8;
    line-height: 1.6;
    font-family: inherit;
  }
`;

const About = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <AboutContainer>
      <AboutContent>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <PageTitle delay={0.2}>About All4 Agency</PageTitle>
          <Description
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            We're a creative agency that combines strategy, design, and technology
            to build brands and digital products that leave a lasting impact.
          </Description>

          <Section variants={itemVariants}>
            <SectionTitle>Our Approach</SectionTitle>
            <Grid>
              <GridItem variants={itemVariants}>
                <h3>Strategy First</h3>
                <p>
                  We begin every project with thorough research and strategic
                  planning to ensure we're solving the right problems and creating
                  meaningful solutions.
                </p>
              </GridItem>
              <GridItem variants={itemVariants}>
                <h3>Design Excellence</h3>
                <p>
                  Our design process focuses on creating intuitive, engaging
                  experiences that resonate with users and strengthen brand
                  connections.
                </p>
              </GridItem>
              <GridItem variants={itemVariants}>
                <h3>Technical Innovation</h3>
                <p>
                  We leverage cutting-edge technology to build scalable,
                  high-performance solutions that drive business growth and user
                  engagement.
                </p>
              </GridItem>
            </Grid>
          </Section>

          <Section variants={itemVariants}>
            <SectionTitle>Our Values</SectionTitle>
            <Grid>
              <GridItem variants={itemVariants}>
                <h3>Innovation</h3>
                <p>
                  We constantly push boundaries and explore new possibilities to
                  create unique and effective solutions.
                </p>
              </GridItem>
              <GridItem variants={itemVariants}>
                <h3>Collaboration</h3>
                <p>
                  We believe in the power of teamwork and partner closely with our
                  clients to achieve exceptional results.
                </p>
              </GridItem>
              <GridItem variants={itemVariants}>
                <h3>Excellence</h3>
                <p>
                  We're committed to delivering the highest quality work that
                  exceeds expectations and creates lasting value.
                </p>
              </GridItem>
            </Grid>
          </Section>
        </motion.div>
      </AboutContent>
    </AboutContainer>
  );
};

export default About;
