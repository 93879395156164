import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import PageTitle from './PageTitle';

const HireUsContainer = styled.div`
  min-height: 100vh;
  padding: 120px 5vw 5vw 5vw;
  background: #0C0C0C;
  color: white;
  font-family: 'Universal Sans Display', sans-serif;
  width: 100%;
  overflow-x: hidden;
`;

const HireUsContent = styled.div`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 60px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const FormColumn = styled.div`
  width: 100%;
`;

const InfoColumn = styled.div`
  position: sticky;
  top: 180px;
  height: fit-content;
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);

  @media (max-width: 1024px) {
    padding: 40px;
  }
`;

const CompanyInfo = styled.div`
  h3 {
    font-size: 3.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
    font-family: 'Universal Sans Display', sans-serif;
    letter-spacing: -0.02em;
    line-height: 1.1;
  }

  p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 3rem;
    line-height: 1.6;
    font-family: 'Open Sans', sans-serif;
  }
`;

const ContactInfo = styled.div`
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  h4 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-family: 'Universal Sans Display', sans-serif;
    letter-spacing: -0.01em;
  }

  p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem;
    line-height: 1.6;
    font-family: 'Open Sans', sans-serif;
  }

  a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      color: white;
      transform: translateX(10px);
    }
  }
`;

const FormStep = styled(motion.div)`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
`;

const StepTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 3rem;
  font-family: 'Universal Sans Display', sans-serif;
  letter-spacing: -0.02em;
  opacity: 0.9;
`;

const InputGroup = styled.div`
  position: relative;
  margin: 2rem 0;
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform-origin: 0 0;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  min-height: 150px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => props.step === 0 ? 'flex-end' : 'space-between'};
  margin-top: 4rem;
  width: 100%;
`;

const StepButton = styled.button`
  padding: 1.2rem 2.5rem;
  margin: 1rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: {
      duration: 0.6,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const HireUs = () => {
  const [step, setStep] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <FormStep
            key="step1"
            {...pageTransition}
          >
            <StepTitle>Tell us about yourself</StepTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Your full name"
                required
                id="name"
              />
              <Label htmlFor="name">Name</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="email" 
                placeholder="Your email address"
                required
                id="email"
              />
              <Label htmlFor="email">Email</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="tel" 
                placeholder="Your phone number"
                id="phone"
              />
              <Label htmlFor="phone">Phone</Label>
            </InputGroup>
            <ButtonContainer step={0}>
              <StepButton onClick={nextStep}>Next →</StepButton>
            </ButtonContainer>
          </FormStep>
        );
      case 1:
        return (
          <FormStep
            key="step2"
            {...pageTransition}
          >
            <StepTitle>Project Details</StepTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Website, Mobile App, Branding..."
                required
                id="projectType"
              />
              <Label htmlFor="projectType">Project Type</Label>
            </InputGroup>
            <InputGroup>
              <FormTextArea 
                placeholder="Tell us about your project..."
                required
                id="description"
              />
              <Label htmlFor="description">Description</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Estimated budget"
                required
                id="budget"
              />
              <Label htmlFor="budget">Budget</Label>
            </InputGroup>
            <ButtonContainer>
              <StepButton onClick={prevStep}>← Back</StepButton>
              <StepButton onClick={nextStep}>Next →</StepButton>
            </ButtonContainer>
          </FormStep>
        );
      case 2:
        return (
          <FormStep
            key="step3"
            {...pageTransition}
          >
            <StepTitle>Final Details</StepTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="When would you like to start?"
                required
                id="timeline"
              />
              <Label htmlFor="timeline">Timeline</Label>
            </InputGroup>
            <InputGroup>
              <FormTextArea 
                placeholder="Anything else we should know?"
                id="additional"
              />
              <Label htmlFor="additional">Additional Info</Label>
            </InputGroup>
            <ButtonContainer>
              <StepButton onClick={prevStep}>← Back</StepButton>
              <StepButton>Submit →</StepButton>
            </ButtonContainer>
          </FormStep>
        );
      default:
        return null;
    }
  };

  return (
    <HireUsContainer>
      <HireUsContent>
        <FormColumn>
          <PageTitle delay={0.2}>Work with Us</PageTitle>
          <AnimatePresence mode="wait">
            {renderStep()}
          </AnimatePresence>
        </FormColumn>
        
        <InfoColumn>
          <CompanyInfo>
            <h3>Let's Create Together</h3>
          </CompanyInfo>
          <ContactInfo>
            <h4>Get in Touch</h4>
            <p>123 Innovation Drive<br />Silicon Valley, CA 94025</p>
            <a href="mailto:hello@all4.agency">hello@all4.agency</a>
            <a href="tel:+1234567890">+1 (234) 567-890</a>
            
            <h4>Working Hours</h4>
            <p>Monday - Friday<br />9:00 AM - 6:00 PM PST</p>
          </ContactInfo>
        </InfoColumn>
      </HireUsContent>
    </HireUsContainer>
  );
};

export default HireUs;
