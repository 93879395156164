import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Navigation from './components/Navigation';
import Home from './components/Home';
import ActualProjectPage from './components/ActualProjectPage';
import CustomCursor from './components/CustomCursor';
import Loader from './components/Loader';
import HireUs from './components/HireUs';
import About from './components/About';
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

// Add Font Awesome icons to library
library.add(fab, fas);

function App() {
  const [loadingComplete, setLoadingComplete] = useState(false);

  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {!loadingComplete && <Loader onComplete={handleLoadingComplete} />}
        {loadingComplete && (
          <>
            <CustomCursor />
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project/:slug" element={<ActualProjectPage />} />
              <Route path="/hire-us" element={<HireUs />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
