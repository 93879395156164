import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageTitle from './PageTitle';
import TitleAnimation from './animations/TitleAnimation';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 120px 5vw 5vw 5vw;
  background: #0C0C0C;
  color: white;
  font-family: 'Universal Sans Display', sans-serif;
`;

const ContactContent = styled.div`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
`;

const Description = styled(motion.p)`
  font-size: 24px;
  max-width: 800px;
  margin-bottom: 4rem;
  opacity: 0.8;
  line-height: 1.6;
  font-family: inherit;
`;

const Form = styled(motion.form)`
  max-width: 800px;
  margin-top: 4rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin: 2rem 0;
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform-origin: 0 0;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  min-height: 150px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 1.2rem 2.5rem;
  margin: 1rem 0;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.8,
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <ContactContainer>
      <ContactContent>
        <ContentWrapper
          as={motion.div}
          variants={containerVariants}
          initial="initial"
          animate="animate"
        >
          <PageTitle delay={0.2}>Get in Touch</PageTitle>
          <Description
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            Whether you have a project in mind or just want to explore
            possibilities, we're here to help bring your vision to life.
          </Description>

          <Form variants={itemVariants} onSubmit={handleSubmit}>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Your full name"
                required
                id="name"
              />
              <Label htmlFor="name">Name</Label>
            </InputGroup>

            <InputGroup>
              <FormInput 
                type="email" 
                placeholder="Your email address"
                required
                id="email"
              />
              <Label htmlFor="email">Email</Label>
            </InputGroup>

            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Subject"
                required
                id="subject"
              />
              <Label htmlFor="subject">Subject</Label>
            </InputGroup>

            <InputGroup>
              <FormTextArea 
                placeholder="Your message"
                required
                id="message"
              />
              <Label htmlFor="message">Message</Label>
            </InputGroup>

            <SubmitButton
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Send Message
            </SubmitButton>
          </Form>
        </ContentWrapper>
      </ContactContent>
    </ContactContainer>
  );
};

export default Contact;
