import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useProject } from '../hooks/useWordPress';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const expandBackground = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const createBackgroundAnimation = (rect, backgroundColor) => keyframes`
  0% {
    clip-path: inset(
      ${rect.y}px 
      ${window.innerWidth - (rect.x + rect.width)}px 
      ${window.innerHeight - (rect.y + rect.height)}px 
      ${rect.x}px
    );
    background-color: ${backgroundColor};
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    clip-path: inset(0 0 0 0);
    background-color: ${backgroundColor};
    opacity: 1;
  }
`;

const createTitleAnimation = (startX, startY, startFontSize) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px);
    font-size: ${startFontSize};
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    font-size: 64px;
    opacity: 1;
  }
`;

const Title = styled.h1`
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: var(--font-universal);
  text-align: left;
  position: relative;
  white-space: nowrap;
  opacity: 0;
  will-change: transform, font-size, opacity;
  
  @media (max-width: 768px) {
    font-size: 36px;
    white-space: normal;
  }
  
  ${({ $startPosition }) => $startPosition && css`
    animation: ${createTitleAnimation(
      $startPosition.x, 
      $startPosition.y, 
      $startPosition.fontSize || '24px'
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  `}
`;

const createVideoAnimation = (startX, startY, startScale) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px) scale(${startScale});
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
`;

const VideoContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin: 2rem auto;
  opacity: 0;
  will-change: transform, opacity;
  ${({ $startPosition, $animate }) => $startPosition && $animate && css`
    animation: ${createVideoAnimation(
      $startPosition.x,
      $startPosition.y,
      $startPosition.scale || 0.5
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.3s;
  `}

  video {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 30px;
  }
`;

const createCategoryAnimation = (startX, startY) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const Description = styled.p`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
  max-width: 800px;
  line-height: 1.6;
  opacity: 0;
  will-change: transform, opacity;
  font-family: var(--font-universal);
  ${({ $startPosition }) => $startPosition && css`
    animation: ${createCategoryAnimation(
      $startPosition.x,
      $startPosition.y
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.15s; // Slight delay after title animation
  `}
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const ProjectDetails = styled.div`
  margin-top: 4rem;
  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.6s;
`;

const ProjectSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h3`
  font-family: var(--font-universal);
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
  letter-spacing: -0.02em;
`;

const SectionText = styled.p`
  font-family: var(--font-universal);
  font-size: 1.8rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.85);
  max-width: 1000px;
  font-weight: 300;
  letter-spacing: -0.02em;
`;

const Categories = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;

  span {
    font-family: var(--font-universal);
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    letter-spacing: -0.02em;
  }
`;

const Category = styled.span`
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: var(--font-universal);
  font-weight: 500;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  img:last-child {
    grid-column: span 3;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;

    img:last-child {
      grid-column: span 1;
    }
  }
`;

const ResultText = styled.div`
  margin-top: 2rem;
  font-family: var(--font-universal);
  font-size: 24px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
`;

const ProjectText = styled.div`
  grid-column: span 3;
  margin-top: 2rem;
  text-align: left;
  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 1s;

  p {
    font-family: var(--font-universal);
    font-size: 1.8rem;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.85);
    max-width: 1000px;
    font-weight: 300;
    letter-spacing: -0.02em;
  }
`;

const TextContent = styled.div`
  h4 {
    font-family: var(--font-universal);
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.9);
  }

  p {
    font-family: var(--font-universal);
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: var(--font-universal);
  color: rgba(255, 255, 255, 0.7);
`;

const LoadingText = styled.p`
  font-size: 24px;
  font-family: var(--font-universal);
  color: rgba(255, 255, 255, 0.7);
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: var(--font-universal);
  color: rgba(255, 255, 255, 0.7);
`;

const ErrorText = styled.p`
  font-size: 24px;
  font-family: var(--font-universal);
  color: rgba(255, 255, 255, 0.7);
`;

const AnimatedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background-color: ${props => props.$backgroundColor};
  transform-origin: center center;
  opacity: 0;
  ${({ $started, $cardRect, $backgroundColor }) => {
    if ($started && $cardRect) {
      return css`
        animation: ${createBackgroundAnimation($cardRect, $backgroundColor)} 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
      `;
    } else {
      return css`
        animation: ${expandBackground} 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
      `;
    }
  }}
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

const AnimatedContainer = styled.div`
  animation: ${scaleIn} 0.8s ease-out;
`;

const PageContainer = styled.div`
  background: transparent;
  color: white;
  padding: 120px 5vw 5vw 5vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Universal Sans Display', sans-serif;
  cursor: none;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ActualProjectPage = () => {
  const { slug } = useParams();
  const { project, loading, error } = useProject(slug);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [rect, setRect] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [animationState, setAnimationState] = useState(null);

  useEffect(() => {
    const transitionData = sessionStorage.getItem('projectCardRect');
    if (transitionData) {
      const rect = JSON.parse(transitionData);
      setAnimationState('direct');
    } else {
      setAnimationState('direct');
    }
  }, []);

  useEffect(() => {
    if (project && project.backgroundColor) {
      setBackgroundColor(project.backgroundColor);
    }
  }, [project]);

  useEffect(() => {
    const savedRect = sessionStorage.getItem('projectCardRect');
    if (savedRect) {
      setRect(JSON.parse(savedRect));
      sessionStorage.removeItem('projectCardRect');
    }
    setAnimationStarted(true);
  }, []);

  if (loading) {
    return (
      <>
        <AnimatedBackground $backgroundColor="#000000" />
        <LoadingContainer>
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      </>
    );
  }

  if (error || !project) {
    return (
      <>
        <AnimatedBackground $backgroundColor="#000000" />
        <ErrorContainer>
          <ErrorText>Project not found</ErrorText>
        </ErrorContainer>
      </>
    );
  }

  const startPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: rect ? rect.y : window.innerHeight / 2,
    fontSize: '24px'
  };

  const categoryStartPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: (rect ? rect.y : window.innerHeight / 2) + 40
  };

  const videoStartPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: rect ? rect.y : window.innerHeight / 2,
    scale: rect ? rect.width / 300 : 0.5
  };

  const cardRect = rect;

  return (
    <>
      <AnimatedBackground
        $started={animationStarted}
        $cardRect={cardRect}
        $backgroundColor={backgroundColor}
      />
      <AnimatedContainer>
        <PageContainer>
          <Content>
            <Title $startPosition={startPosition}>
              {project.title}
            </Title>
            <Description id="projectDescription" $startPosition={categoryStartPosition}>
              {project.description}
            </Description>
            <VideoContainer id="projectVideo" $startPosition={videoStartPosition} $animate={!!videoStartPosition}>
              <video
                autoPlay
                muted
                loop
                playsInline
                src={project.pageVideo}
              />
            </VideoContainer>

            <ProjectDetails>
              <ProjectSection>
                <SectionTitle>About the Project</SectionTitle>
                <SectionText>{project.fullDescription}</SectionText>
              </ProjectSection>
              <ProjectSection>
                <SectionTitle>Categories</SectionTitle>
                <Categories>
                  {project.categories.map((category, index) => (
                    <Category key={index}>{category}</Category>
                  ))}
                </Categories>
              </ProjectSection>
            </ProjectDetails>

            <ImageGrid>
              {project.images.map((image, index) => (
                <img key={index} src={image} alt={`Project image ${index + 1}`} />
              ))}
            </ImageGrid>

            <ResultText>
              {project.resultText}
            </ResultText>
          </Content>
        </PageContainer>
      </AnimatedContainer>
    </>
  );
}

export default ActualProjectPage;
