import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { motion, useAnimation, AnimatePresence, useInView } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import ClientCarousel from './ClientCarousel';
import ReactPlayer from 'react-player';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger plugin
import SplitType from 'split-type';
import Team from './Team';
import useIsMobile from '../hooks/useIsMobile';
import PageTitle from './PageTitle';
import TitleAnimation from './animations/TitleAnimation'; // Import TitleAnimation
import { useProjects } from '../hooks/useWordPress';

gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin

const GlobalStyle = createGlobalStyle`
  body, h1, h2, h3, h4, h5, h6, p, span, div, a, button, input, textarea {
    font-family: 'Universal Sans Display', sans-serif !important;
  }
`;

const HomeContainer = styled.div`
  background: #0f0f0f;
  color: #ffffff;
  min-height: 100vh;
  padding: 120px 5vw 5vw 5vw;
  overflow: visible;
`;

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  font-family: inherit;
`;

const HeroContent = styled(motion.div)`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
`;

const AnimatedText = styled(motion.div)`
  opacity: 0;
`;

const HeroText = styled(motion.p)`
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2rem;
  max-width: 600px;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: #000;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  cursor: pointer;
`;

const HeroVideoContainer = styled(motion.div)`
  width: 100%;
  max-width: 1400px;
  position: relative;
  overflow: visible;
  border-radius: 24px;
  margin: 2rem auto 0;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  transform-origin: center center;
  transition: transform 0.3s ease-out;
  z-index: 10;
`;

const FullscreenVideoContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  .react-player {
    width: 100% !important;
    height: 100% !important;
  }

  video {
    object-fit: cover;
  }
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1001;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
  }
  
  &::after {
    transform: rotate(-45deg);
  }
`;

const NewSection = styled.section`
  padding: 120px 0;
  background: #0f0f0f;
  position: relative;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const NewText = styled(motion.p)`
  font-size: clamp(1.5rem, 2vw, 2rem);
  max-width: 600px;
  margin-bottom: 3rem;
  opacity: 0.8;
`;

const NewList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: clamp(1.5rem, 2vw, 2rem);
`;

const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  padding: 0 1rem;

  @media (max-width: 1024px) {
    max-width: none;
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  cursor: pointer;
  font-size: clamp(1.8rem, 3vw, 2.2rem);
  width: 100%;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AccordionContent = styled(motion.div)`
  overflow: hidden;
  width: 100%;
`;

const AccordionInner = styled.div`
  padding-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.4;
`;

const AccordionIcon = styled(motion.div)`
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 2rem;
  flex-shrink: 0;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    background: #888888; /* Darker color */
    transition: transform 0.3s ease;
  }
  
  &::before {
    width: 100%;
    height: 1px; /* Thinner line */
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
  &::after {
    width: 1px; /* Thinner line */
    height: 100%;
    left: 50%;
    top: 0;
    transform: ${props => props.isOpen ? 'translateX(-50%) scaleY(0)' : 'translateX(-50%)'};
  }
`;

const AccordionSection = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionWrapper>
      {items.map((item, index) => {
        const isOpen = activeIndex === index;
        
        return (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => toggleAccordion(index)}>
              <span>{item.title}</span>
              <AccordionIcon isOpen={isOpen} />
            </AccordionHeader>
            <AnimatePresence initial={false}>
              {isOpen && (
                <AccordionContent
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { height: "auto", opacity: 1 },
                    collapsed: { height: 0, opacity: 0 }
                  }}
                  transition={{
                    duration: 0.25,
                    ease: [0.4, 0, 0.2, 1]
                  }}
                >
                  <AccordionInner>
                    {item.description}
                  </AccordionInner>
                </AccordionContent>
              )}
            </AnimatePresence>
          </AccordionItem>
        );
      })}
    </AccordionWrapper>
  );
};

const FeaturedProjectsSection = styled.section`
  padding: 100px 0;
  background: #0C0C0C;
  color: white;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const FeaturedProjectsContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 80%;
    max-width: 400px;
    gap: 2.5rem;
  }
`;

const StyledProjectCard = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    color: white;
    transform: translateY(100%);
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    
    h3 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    
    p {
      font-size: 1rem;
      opacity: 0.8;
    }
  }

  @media (min-width: 769px) {
    &:hover {
      video {
        transform: scale(1.05);
      }
      .content {
        transform: translateY(0);
      }
    }

    video {
      ${props => !props.$isHovered && css`
        filter: none;
      `}
    }
  }

  @media (max-width: 768px) {
    &.in-view {
      video {
        transform: scale(1.05);
        filter: none;
      }
      .content {
        transform: translateY(0);
      }
    }
  }
`;

const ProjectCard = ({ project, index }) => {
  const cardRef = useRef(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const videoRef = useRef(null);

  useEffect(() => {
    if (isMobile && cardRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsInView(true);
              if (videoRef.current) {
                videoRef.current.play();
              }
            } else {
              setIsInView(false);
              if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
              }
            }
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(cardRef.current);
      return () => observer.disconnect();
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile && videoRef.current) {
      if (isHovered) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isHovered, isMobile]);

  const handleClick = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      sessionStorage.setItem('projectCardRect', JSON.stringify(rect));
      navigate(`/project/${project.slug}`);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div 
      className="project-card-hover"
      style={{ 
        width: '100%', 
        height: '100%',
        position: 'relative',
      }}
      onClick={handleClick}
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledProjectCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: index * 0.1 }}
        $isHovered={isHovered}
        className={isInView ? 'in-view' : ''}
      >
        <video
          ref={videoRef}
          src={project.cardVideo}
          muted
          loop
          playsInline
          autoPlay={isMobile}
          onLoadedMetadata={() => setIsVideoLoaded(true)}
        />
        <div className="content">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
        </div>
      </StyledProjectCard>
    </div>
  );
};

const SeeAllProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const LetsWorkTogetherButton = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  color: white;
  font-size: 1.5rem;
  font-family: 'Universal Sans Display', sans-serif;
  text-decoration: none;
  border-radius: 50px;
  border: 2px solid white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
`;

const ContactSection = styled.section`
  padding: 100px 0;
  background: #0C0C0C;
  color: white;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const ContactContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;
`;

const ContactTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 4rem;
  text-align: center;
  font-family: 'Universal Sans Display', sans-serif;
  letter-spacing: -0.02em;
`;

const ContactFormWrapper = styled(motion.form)`
  max-width: 800px;
  margin: 0 auto;
`;

const ContactInputGroup = styled.div`
  position: relative;
  margin: 2rem 0;
`;

const ContactLabel = styled.label`
  position: absolute;
  left: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform-origin: 0 0;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

const ContactInput = styled.input`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${ContactLabel}, &:not(:placeholder-shown) + ${ContactLabel} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const ContactTextArea = styled.textarea`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  min-height: 150px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &:focus + ${ContactLabel}, &:not(:placeholder-shown) + ${ContactLabel} {
    transform: translateY(-1.5rem) scale(0.8);
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    color: transparent;
  }
`;

const ContactButton = styled(motion.button)`
  padding: 1.2rem 2.5rem;
  margin: 1rem 0;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const Footer = styled.footer`
  padding: 4rem 5vw;
  background: #0f0f0f;
  color: white;
  border-top: 1px solid #222;
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
`;

const FooterColumn = styled.div`
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 0.8rem;
  }
  
  a {
    color: #999;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: white;
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #222;
  color: #666;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 2.5rem);
  margin-bottom: 4rem;
  text-align: center;
  color: ${props => props.light ? '#fff' : '#000'};
  font-weight: 500;
  letter-spacing: -0.02em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Universal Sans Display', sans-serif;
`;

const serviceDescriptions = [
  {
    title: "3D & 2D",
    description: "Elevate your brand with cutting-edge 3D animations and stunning 2D visuals. Our team creates immersive experiences that bring your ideas to life, from product visualizations to animated brand stories that captivate your audience."
  },
  {
    title: "Digital Products",
    description: "Transform your vision into powerful digital solutions. We design and develop intuitive mobile apps, web applications, and digital platforms that solve real problems and deliver exceptional user experiences."
  },
  {
    title: "Websites",
    description: "Create stunning, responsive websites that leave a lasting impression. Our web solutions combine beautiful design with powerful functionality, ensuring your online presence stands out and drives results."
  },
  {
    title: "Development",
    description: "Build robust and scalable digital solutions with our expert development team. From front-end to back-end, we use cutting-edge technologies to create custom software that powers your business growth."
  },
  {
    title: "Generative AI",
    description: "Harness the power of artificial intelligence to revolutionize your business. We integrate advanced AI solutions to automate processes, generate creative content, and provide personalized experiences that set you apart."
  }
];

const Home = () => {
  const { projects, loading, error } = useProjects();
  const [videoContainerScale, setVideoContainerScale] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHeroVisible, setIsHeroVisible] = useState(true);
  const [showTeam, setShowTeam] = useState(false);
  const [showTeamVideo, setShowTeamVideo] = useState(false);
  const [isTeamVideoPlaying, setIsTeamVideoPlaying] = useState(false);
  const [isTeamVideoMuted, setIsTeamVideoMuted] = useState(true);
  const [isTeamVideoLoaded, setIsTeamVideoLoaded] = useState(false);
  const [isHoveringVideo, setIsHoveringVideo] = useState(false);
  const [formState, setFormState] = useState({
    name: { value: '', focused: false, error: '', valid: false },
    email: { value: '', focused: false, error: '', valid: false },
    message: { value: '', focused: false, error: '', valid: false }
  });
  
  const videoRef = useRef(null);
  const teamVideoRef = useRef(null);
  const playerRef = useRef(null);
  const videoContainerRef = useRef(null);
  const navigate = useNavigate();
  const controls = useAnimation();
  
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);

      // Calculate scale based on scroll position with a max scroll threshold
      const maxScrollForScale = 500; // Adjust this value to control when scaling stops
      const normalizedPosition = Math.min(position, maxScrollForScale);
      const scale = 1 + (normalizedPosition / maxScrollForScale) * 0.1; // Max scale is 1.1

      setVideoContainerScale(scale);

      // Maintain margin between video and text
      if (videoContainerRef.current) {
        videoContainerRef.current.style.marginTop = `${100 - (scale - 1) * 50}px`;
      }

      // Check if we've scrolled to 100px before reaching the video
      if (videoContainerRef.current) {
        const videoPosition = videoContainerRef.current.getBoundingClientRect().top;
        const shouldHideMenu = videoPosition <= 100;
        window.dispatchEvent(new CustomEvent('toggleMenu', { detail: { hide: shouldHideMenu } }));
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const validateField = (name, value) => {
    let error = '';
    let valid = true;

    switch (name) {
      case 'name':
        if (value.length < 2) {
          error = 'Name must be at least 2 characters';
          valid = false;
        }
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          error = 'Please enter a valid email';
          valid = false;
        }
        break;
      case 'message':
        if (value.length < 10) {
          error = 'Message must be at least 10 characters';
          valid = false;
        }
        break;
      default:
        break;
    }

    return { error, valid };
  };

  const handleFocus = (name) => {
    setFormState(prev => ({
      ...prev,
      [name]: { ...prev[name], focused: true }
    }));
  };

  const handleBlur = (name) => {
    setFormState(prev => ({
      ...prev,
      [name]: { ...prev[name], focused: false }
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const { error, valid } = validateField(name, value);

    setFormState(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value,
        error,
        valid
      }
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields
    const isValid = Object.keys(formState).every(key => formState[key].valid);
    
    if (!isValid) {
      // Show error animations for invalid fields
      Object.keys(formState).forEach(key => {
        if (!formState[key].valid) {
          const input = document.querySelector(`[name="${key}"]`);
          input.classList.add('error');
          setTimeout(() => input.classList.remove('error'), 500);
        }
      });
      return;
    }

    // Add form submission logic here
    console.log('Form submitted:', formState);
  };

  const serviceList = [
    {
      icon: '\uf121', // Code icon
      title: 'Web Development',
      description: 'We craft modern, responsive websites that engage users and drive results. Our development process focuses on performance, accessibility, and user experience.'
    },
    {
      icon: '\uf3cd', // Mobile icon
      title: 'Mobile Apps',
      description: 'Native and cross-platform mobile applications that provide seamless experiences across all devices and operating systems.'
    },
    {
      icon: '\uf53f', // Palette icon
      title: 'UI/UX Design',
      description: 'User-centered design solutions that combine aesthetics with functionality, creating intuitive and engaging digital experiences.'
    },
    {
      icon: '\uf0eb', // Lightbulb icon
      title: 'Digital Strategy',
      description: 'Strategic planning and consultation to help businesses navigate the digital landscape and achieve their goals.'
    },
    {
      icon: '\uf1fc', // Brush icon
      title: 'Brand Identity',
      description: 'Comprehensive branding solutions that help businesses stand out in the digital space with unique and memorable identities.'
    },
    {
      icon: '\uf201', // Chart icon
      title: 'Digital Marketing',
      description: 'Data-driven marketing strategies that increase visibility, engage audiences, and drive conversions across digital channels.'
    }
  ];

  const team = [
    {
      name: "John Doe",
      role: "Creative Director",
      image: "/team/member1.jpg"
    },
    {
      name: "Jane Smith",
      role: "Lead Designer",
      image: "/team/member2.jpg"
    },
    {
      name: "Mike Johnson",
      role: "Tech Lead",
      image: "/team/member3.jpg"
    },
    {
      name: "Sarah Williams",
      role: "Project Manager",
      image: "/team/member4.jpg"
    }
  ];

  const handleVideoClick = () => {
    setIsFullscreen(true);
    setIsVideoPlaying(true);
  };

  const handleClose = () => {
    setIsFullscreen(false);
  };

  const handleVideoMouseEnter = () => {
    setIsHoveringVideo(true);
  };

  const handleVideoMouseLeave = () => {
    setIsHoveringVideo(false);
  };

  useEffect(() => {
    const splitHeroTitle = new SplitType('.hero-title-text', { types: 'words', wordClass: 'word' });

    gsap.fromTo(
      '.word',
      {
        y: 20,
        opacity: 0,
        scale: 0.9,
      },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 0.7,
        stagger: 0.1,
        ease: 'power2.out',
      }
    );

    return () => {
      gsap.killTweensOf('.word');
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll('.slide-in');

    elements.forEach((element, index) => {
      gsap.fromTo(element, {
        x: -100,
        opacity: 0
      }, {
        x: 0,
        opacity: 1,
        delay: index * 0.2, // Stagger animation for each element
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          end: 'top 60%',
          scrub: true
        }
      });
    });
  }, []);

  const featuredProjects = projects || [];

  return (
    <>
      <GlobalStyle />
      <HomeContainer>
        <HeroSection>
          <HeroContent>
            <TitleAnimation>
              <PageTitle delay={0.2}>
                All4 is a global agency specializing in branding, 3D motion, and UX design.
              </PageTitle>
            </TitleAnimation>
            <AnimatedText
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.8 }}
            >
              <HeroText>
                We create immersive digital experiences that connect brands with their audience in meaningful ways.
              </HeroText>
            </AnimatedText>
            <HeroVideoContainer
              ref={videoContainerRef}
              style={{ transform: `scale(${videoContainerScale})` }}
            >
              <VideoClickArea 
                className="video-click-area"
                onMouseEnter={handleVideoMouseEnter}
                onMouseLeave={handleVideoMouseLeave}
                onClick={() => {
                  setIsFullscreen(true);
                  setIsVideoPlaying(true);
                }}
              />
              <ReactPlayer
                ref={playerRef}
                url="/videos/all4.mp4"
                playing={isVideoPlaying}
                muted={isVideoMuted}
                width="100%"
                height="100%"
                loop={true}
                playsinline={true}
                onReady={() => setIsVideoLoaded(true)}
              />
            </HeroVideoContainer>

            <AnimatePresence>
              {isFullscreen && (
                <FullscreenVideoContainer
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <CloseButton onClick={handleClose} />
                  <ReactPlayer
                    className="react-player"
                    url="/videos/all4.mp4"
                    playing={isVideoPlaying}
                    controls
                    width="100%"
                    height="100%"
                    config={{
                      file: {
                        attributes: {
                          style: {
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                          }
                        }
                      }
                    }}
                  />
                </FullscreenVideoContainer>
              )}
            </AnimatePresence>
          </HeroContent>
        </HeroSection>

        <div className="main-content">
          <NewSection>
            <SectionContainer>
              <Column>
                <NewText
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  We build transformative digital experiences for the world's leading brands by blending AI, design, and technology.
                </NewText>
              </Column>
              <Column>
                <AccordionSection items={serviceDescriptions} />
              </Column>
            </SectionContainer>
          </NewSection>

          <ClientCarousel />

          <FeaturedProjectsSection>
            <FeaturedProjectsContent>
              <AnimatedText
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <SectionTitle light>Featured Projects</SectionTitle>
              </AnimatedText>
              <ProjectsGrid>
                {featuredProjects.map((project, index) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    index={index}
                  />
                ))}
              </ProjectsGrid>
              <SeeAllProjectsContainer>
                <LetsWorkTogetherButton to="/hire-us">
                  Let's Create Together
                </LetsWorkTogetherButton>
              </SeeAllProjectsContainer>
            </FeaturedProjectsContent>
          </FeaturedProjectsSection>

          <Team />

          <ContactSection>
            <ContactContent>
              <ContactTitle>Get in Touch</ContactTitle>
              <ContactFormWrapper
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <ContactInputGroup>
                  <ContactInput type="text" name="name" placeholder=" " required />
                  <ContactLabel>Name</ContactLabel>
                </ContactInputGroup>
                <ContactInputGroup>
                  <ContactInput type="email" name="email" placeholder=" " required />
                  <ContactLabel>Email</ContactLabel>
                </ContactInputGroup>
                <ContactInputGroup>
                  <ContactTextArea name="message" placeholder=" " required />
                  <ContactLabel>Message</ContactLabel>
                </ContactInputGroup>
                <ContactButton
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Send Message
                </ContactButton>
              </ContactFormWrapper>
            </ContactContent>
          </ContactSection>

          <Footer>
            <FooterContent>
              <FooterColumn>
                <h3>About Us</h3>
                <p style={{ color: '#999', lineHeight: '1.6' }}>
                  We're a creative agency passionate about crafting exceptional digital experiences that drive real business results.
                </p>
              </FooterColumn>
              <FooterColumn>
                <h3>Services</h3>
                <ul>
                  <li><Link to="/services">Brand Strategy</Link></li>
                  <li><Link to="/services">Web Development</Link></li>
                  <li><Link to="/services">Mobile Apps</Link></li>
                  <li><Link to="/services">Motion Design</Link></li>
                </ul>
              </FooterColumn>
              <FooterColumn>
                <h3>Contact</h3>
                <ul>
                  <li><a href="mailto:hello@agency.com">hello@agency.com</a></li>
                  <li><a href="tel:+1234567890">+1 (234) 567-890</a></li>
                  <li>123 Creative Street<br />San Francisco, CA 94103</li>
                </ul>
              </FooterColumn>
              <FooterColumn>
                <h3>Follow Us</h3>
                <ul>
                  <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                  <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                  <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                  <li><a href="https://dribbble.com" target="_blank" rel="noopener noreferrer">Dribbble</a></li>
                </ul>
              </FooterColumn>
            </FooterContent>
            <Copyright>
              &copy; {new Date().getFullYear()} Agency. All rights reserved.
            </Copyright>
          </Footer>
        </div>
      </HomeContainer>
    </>
  );
};

export default Home;
