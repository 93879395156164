import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SplitType from 'split-type';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const TitleWrapper = styled(motion.div)`
  margin-bottom: 2rem;
  overflow: hidden;
`;

const StyledTitle = styled(motion.h1)`
  font-size: 104px;
  line-height: 1;
  font-family: var(--font-universal);
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-style: normal;
  margin: 0;
  padding: 0;
  opacity: 0;

  @media (max-width: 1200px) {
    font-size: clamp(4rem, 8vw, 104px);
  }

  @media (max-width: 768px) {
    font-size: clamp(3rem, 8vw, 7rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(2.5rem, 8vw, 4rem);
  }
`;

const PageTitle = ({ children, delay = 0 }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    // Set initial state immediately
    gsap.set('.title-word', {
      y: 20,
      opacity: 0,
    });

    const text = new SplitType(titleRef.current, {
      types: 'words',
      wordClass: 'title-word'
    });

    // Set initial state of the container
    gsap.set(titleRef.current, {
      opacity: 1
    });

    gsap.fromTo(
      '.title-word',
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.7,
        stagger: 0.1,
        ease: 'power2.out',
        delay: delay
      }
    );

    return () => {
      if (text) text.revert();
    };
  }, [delay]);

  return (
    <TitleWrapper>
      <StyledTitle ref={titleRef}>
        {children}
      </StyledTitle>
    </TitleWrapper>
  );
};

export default PageTitle;
