import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faApple,
  faMicrosoft,
  faAmazon,
  faFacebook,
  faSpotify,
  faGithub,
  faLinkedin,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

const CarouselContainer = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  padding: 5rem 0;
  background: #0f0f0f;
`;

const CarouselInner = styled.div`
  display: flex;
  width: 200vw;
  flex-wrap: nowrap;
`;

const CarouselTrack = styled(motion.div)`
  display: inline-flex;
  gap: 4rem;
  white-space: nowrap;
  will-change: transform;
  align-items: center;
  padding: 0 2rem;
  min-width: 100%;
`;

const ClientItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
  opacity: 0.4;
  transition: all 0.3s ease;
  padding: 1rem 0;
  
  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  svg {
    width: 50px !important;
    height: 50px !important;
  }
`;

const ClientName = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

const ClientCarousel = () => {
  const clients = [
    { icon: faGoogle, name: 'Google' },
    { icon: faApple, name: 'Apple' },
    { icon: faMicrosoft, name: 'Microsoft' },
    { icon: faAmazon, name: 'Amazon' },
    { icon: faFacebook, name: 'Facebook' },
    { icon: faSpotify, name: 'Spotify' },
    { icon: faGithub, name: 'Github' },
    { icon: faLinkedin, name: 'LinkedIn' },
    { icon: faTwitter, name: 'Twitter' },
    { icon: faInstagram, name: 'Instagram' }
  ];

  return (
    <CarouselContainer>
      <CarouselInner>
        <CarouselTrack
          animate={{
            x: [0, -1500]
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 35,
              ease: "linear",
            },
          }}
        >
          {[...clients, ...clients, ...clients].map((client, index) => (
            <ClientItem key={index}>
              <FontAwesomeIcon icon={client.icon} />
              <ClientName>{client.name}</ClientName>
            </ClientItem>
          ))}
        </CarouselTrack>
      </CarouselInner>
    </CarouselContainer>
  );
};

export default ClientCarousel;
