import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

const LogoSvg = styled.svg`
  width: 60px;
  height: auto;
  
  .cls-1 {
    fill: currentColor;
    stroke-width: 0px;
  }
`;

const Logo = () => {
  const logoRef = useRef(null);

  useEffect(() => {
    const [elements] = logoRef.current.children;
    const paths = elements.querySelectorAll('path, polygon');

    gsap.fromTo(
      paths,
      { scale: 0, rotation: -180, fill: '#fff', transformOrigin: 'center' },
      { scale: 1, rotation: 0, fill: '#ff6347', duration: 1.5, ease: 'elastic.out(1, 0.3)', stagger: 0.1 }
    );

    gsap.to(paths, {
      scale: 1.1,
      rotation: 5,
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut',
      stagger: 0.2
    });
  }, []);

  return (
    <LogoSvg ref={logoRef} viewBox="0 0 100.8 93.6" xmlns="http://www.w3.org/2000/svg">
      <path className="cls-1" d="M24.4,86.6c-5.3-8.7-1-21.5-1-21.5,0,0-2.5,5-3.6,7.5C12.6,87.9,5.4,92.3,0,93.6h38.9c-4.1-.2-11-1.3-14.5-7h0Z"/>
      <polygon className="cls-1" points="100.8 93.6 55.4 0 37.8 36.3 65.6 93.6 100.8 93.6"/>
    </LogoSvg>
  );
};

export default Logo;
